<template>
    <section class="lock-install" @click.stop="selectclick">
        <div class="top-bar bg-white align-center">
            <el-date-picker @change="pickerclickitr"   ref="datePickerRef" v-model="dateArr" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"/>
            <el-input v-model="formSearch.keyword" style="margin: 5px; width: 250px" size="small"
                      placeholder="请输入房间、安装人、联系电话搜索"/>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset" style="margin: 0 7px;">重置</el-button>
            <!--<el-button type="primary" size="small" @click="addLockInstall" icon="el-icon-plus"
                       v-if="formSearch.communityUuid">添加</el-button>-->
            <el-button type="primary" size="small" icon="el-icon-download" @click="lockInstallExport">导出</el-button>
        </div>

        <div class="table-container bg-white flex">
            <div style="width: 17%;height: 790px;">
                <div style="border-right: 1px #EBEEF5 solid;height: 790px;">
                    <r-e-tree ref="communityRef" :data="communityTreeData" :default-expanded-keys="defaultTreeNodeKeys"
                              :node-label="createNodeLabel" expand-on-click-node @node-click="handleClickCommunity"/>
                </div>
            </div>
            <div style="width: 83%;">
                <r-e-table ref="tableRef" :dataRequest="getLockInstallList" :columns="tableColumn" :query="formSearch"
                           :height="790">
                    <el-table-column slot="toolbar" fixed="right" label="操作" width="120">
                        <template slot-scope="{ row }">
                            <div class="table-tools">
                                <span class="table-btn" v-if="!row.renovationOk" @click="renovationHandle(row)">
                                    已装修
                                </span>
                                <span class="table-btn" @click="lookDetail(row)">查看</span>
                            </div>
                        </template>
                    </el-table-column>
                </r-e-table>
            </div>
        </div>

        <r-e-dialog title="详情" :visible.sync="detailShow" top="5vh" width="1100px">
            <el-form ref="formPublish" label-width="100px" size="small">
                <div class="flex">
                    <el-form-item label="安装房间">
                        <el-input v-model="lookDetailData.apartmentName" disabled style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="安装人">
                        <el-input v-model="lookDetailData.installationPersonnel" disabled style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="lookDetailData.phone" disabled style="width: 225px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="安装时间">
                        <el-date-picker v-model="lookDetailData.installTime" disabled type="datetime"
                                        placeholder="选择日期时间" style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="联网方式">
                        <el-input v-model="lookDetailData.networkingTypeName" disabled style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="年限">
                        <el-input v-model="lookDetailData.nbYearNumber" disabled style="width: 225px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="门锁型号">
                        <el-input v-model="lookDetailData.lockModel" disabled style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="NB供应商">
                        <div style="width: 225px;">
                            <el-radio-group v-model="lookDetailData.supplier" disabled>
                                <el-radio label="联通">联通</el-radio>
                                <el-radio label="移动">移动</el-radio>
                                <el-radio label="电信">电信</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="锁体">
                        <el-input v-model="lookDetailData.lockBody" disabled style="width: 225px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="安装时电量">
                        <el-input v-model="lookDetailData.electricNum" disabled style="width: 225px;"/>
                    </el-form-item>

                    <!-- 2023.07.19张晓瑜新增 -->
                    <el-form-item label="所属地">
                        <div style="width: 225px;">
                            <el-input v-model="lookDetailData.lockPlace" disabled style="width: 225px;"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="厂家名称">
                        <el-input v-model="lookDetailData.manufacturerName" disabled style="width: 225px;"/>
                    </el-form-item>
                    <el-form-item label="">
                        <!--                        <el-button type="primary" @click="testPassword">测试密码</el-button>-->
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="安装时是否在线" label-width="110px">
                        <div style="width: 215px;">
                            <el-radio-group v-model="lookDetailData.installingIsOnline" disabled>
                                <el-radio :label="3" style="margin-right: 25px!important;">在线</el-radio>
                                <el-radio :label="4">离线</el-radio>
                                <el-radio :label="0">异常</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="安装前图片">
                        <div class="flex justify-center"
                             style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;">
                            <el-image :src="createFullImageUrl(lookDetailData.installBefore)" class="flex align-center"
                                      :preview-src-list="[createFullImageUrl(lookDetailData.installBefore)]">
                                <div slot="error" style="font-size: 30px;">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                                <div slot="placeholder">加载中...</div>
                            </el-image>
                        </div>
                    </el-form-item>
                    <el-form-item label="安装中图片">
                        <div class="flex justify-center"
                             style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;">
                            <el-image :src="createFullImageUrl(lookDetailData.installing)" class="flex align-center"
                                      :preview-src-list="[createFullImageUrl(lookDetailData.installing)]">
                                <div slot="error" style="font-size: 30px;">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                                <div slot="placeholder">加载中...</div>
                            </el-image>
                        </div>
                    </el-form-item>
                    <el-form-item label="安装后图片">
                        <div class="flex justify-center"
                             style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;">
                            <el-image :src="createFullImageUrl(lookDetailData.installAfter)" class="flex align-center"
                                      :preview-src-list="[createFullImageUrl(lookDetailData.installAfter)]">
                                <div slot="error" style="font-size: 30px;">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                                <div slot="placeholder">加载中...</div>
                            </el-image>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="lookDetailData.comment" disabled style="width: 875px;"/>
                    </el-form-item>
                </div>

                <div class="flex" v-if="msg">
                    <el-form-item label="密码提示">
                        <el-input type="textarea" v-model="msg" readonly style="width: 875px;"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <dialog-save-lock-install ref="dialogSaveLockInstall" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import {tableColumn} from "./data";
import {getLockInstallList, lockInstallExport, testPassword, renovation} from "@/api/lock-install";
import {createFullImageUrl} from "@/components/Upload";
import {timeFormat} from "@custom/index";
import {downloadByData} from "@/utils/hooks/download";
import {MessageSuccess} from "@custom/message";

export default {
    name: "lock-install",
    data() {
        return {
            itr:true,
            tableColumn,
            formSearch: {
                keyword: null,
                communityUuid: null,
                startDate: null,
                endDate: null,
            },
            communityTreeData: [],
            defaultTreeNodeKeys: [],
            mySetInterval: null,
            lookDetailData: {},
            detailShow: false,
            dateArr: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            msg: null
        };
    },
    components: {
        dialogSaveLockInstall: () => import("./components/dialog-save-lock-install"),
    },
    deactivated(){
    // alert('离开')
    // 2024-7-10-陈守亮处理切换页面关闭组件
       this.selectclick()
    },
    methods: {
        handleSearch(isSearch = true) {
            // 搜索
            this.$refs["tableRef"].getTableData(isSearch);
        },

        handleReset() {
            this.formSearch = {keyword: null, communityUuid: null, startDate: null, endDate: null,};
            this.dateArr = [];
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },

        getLockInstallList(params) {
            return getLockInstallList(params);
        },

        createNodeLabel(h, node) {
            // 生成tree节点，房源数量
            return h(
                "span", {
                    class: "bound-count",
                    style: {
                        color: "#2688F6", fontWeight: "bold"
                    }
                },
                node.data.apartmentsCount
            );
        },

        handleClickCommunity(data) {
            const {uuid} = data;
            this.formSearch.communityUuid = uuid;
            this.handleSearch();
        },

        async getCommunityTreeData(currentNode) {
            // 获取所有小区楼幢单元树tree数据
            const {list = []} = await this.$store.dispatch("community/GetCommunityListActions");
            this.communityTreeData = list;
            for (let item of list) delete item['children'];
        },

        lookDetail(data) {
            this.lookDetailData = data;
            console.log('data', data);
            this.msg = null;
            this.detailShow = true;
        },

        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },

        addLockInstall() {
            let formSearch = this.formSearch;
            this.$refs["dialogSaveLockInstall"].openDialog({...formSearch});
        },

        lockInstallExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let {formSearch} = this;
            let paramsData = {...formSearch}
            lockInstallExport(paramsData).then(res => {
                downloadByData({data: res, filename: `门锁安装${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
            }).finally(() => loading.close());
        },

        testPassword() {
            let that = this;
            let {apartmentUuid} = that.lookDetailData;
            let loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            testPassword({apartmentUuid}).then(res => {
                let {msg} = res;
                that.msg = msg;
                MessageSuccess('发送成功');

            }).finally(() => loading.close());
        },

        renovationHandle({uuid}) {
            let that = this;
            that.$confirm('此操作将此房间改为已装修, 是否继续?', '注意', {type: 'warning'}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                renovation({uuid}).then(res => {
                    MessageSuccess('装修成功');
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },
      selectclick(){
        console.log( this.itr)
        this.itr = !this.itr;
        if(this.itr){
          this.$refs['datePickerRef'].hidePicker();
        }
      },
      pickerclickitr(){
        this.itr = true;
        console.log(this.itr)
      },
    },
    async mounted() {
        // 获取所有小区楼幢单元
        await this.getCommunityTreeData();
    },
    watch: {
        dateArr(value) {
            if (value && value.length === 2) {
                let [startDate, endDate] = value;
                this.formSearch.startDate = startDate;
                this.formSearch.endDate = endDate;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.lock-install {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: center;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
