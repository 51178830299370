import {timeFormat} from "@custom";
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },

    {
        prop: "createTime",
        label: "创建时间",
        width: 150,
        render: (h, {row}) => {
            let {createTime} = row;
            createTime = createTime ? timeFormat(new Date(createTime), "yyyy-MM-dd HH:mm:ss") : "";
            return h("span", createTime);
        }
    },
    {
        prop: "apartmentName",
        label: "房间",
        width: 200,
    },
    {
        prop: "installationPersonnel",
        label: "安装人",
        width: 80,
    },
    /*{
        prop: "phone",
        label: "联系电话",
        width: 100,
        render: (h, {row}) => {
            let {phone} = row;
            const leasorPhone = phone.substring(0, 3) + "****" + phone.substring(phone.length - 4);
            return h("span", leasorPhone);
        }
    },*/
    {
        prop: "installTime",
        label: "安装时间",
        width: 150,
        render: (h, {row}) => {
            let {installTime} = row;
            installTime = installTime ? timeFormat(new Date(installTime), "yyyy-MM-dd HH:mm:ss") : "";
            return h("span", installTime);
        }
    },
    {
        prop: "lockBody",
        label: "锁体",
        width: 130,
    },
    // 2023.07.19张晓瑜新增
    {
        prop: "manufacturerName",
        label: "厂家名称",
        width: 130,
    },
    {
        prop: "lockPlace",
        label: "所属地",
        width: 130,
    },
    {
        prop: "networkingTypeName",
        label: "联网方式",
        width: 80,
    },
    {
        prop: "supplier",
        label: "运营商",
        width: 80,
    },
    {
        prop: "renovationOk",
        label: "是否装修",
        width: 80,
        render: (h, {row}) => {
            let {renovationOk} = row;
            return h("span", renovationOk ? "已装修" : "未装修");
        }
    },
    {
        prop: "installingIsOnline",
        label: "是否在线",
        width: 80,
        render: (h, {row}) => {
            // let {installingIsOnline} = row;
            // return h("span", installingIsOnline === 3 ? "在线" : "离线");
            return "在线";
        }
    },
    {
        prop: "onlinePasswordIsok",
        label: "在线密码状态",
        width: 100,
        render: (h, {row}) => {
            let {onlinePasswordIsok} = row;
            // return h("span", onlinePasswordIsok ? "正常" : "异常");
            return "正常";
        }
    },
    {
        prop: "offPasswordIsok",
        label: "离线密码状态",
        width: 100,
        render: (h, {row}) => {
            let {offPasswordIsok} = row;
            // return h("span", offPasswordIsok ? "正常" : "异常");
            return "正常";
        }
    },
    {
        prop: "comment",
        label: "备注",
    },
];